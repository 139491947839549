// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-layouts-news-list-page-tsx": () => import("./../../../src/layouts/NewsListPage.tsx" /* webpackChunkName: "component---src-layouts-news-list-page-tsx" */),
  "component---src-layouts-news-page-tsx": () => import("./../../../src/layouts/NewsPage.tsx" /* webpackChunkName: "component---src-layouts-news-page-tsx" */),
  "component---src-layouts-project-page-tsx": () => import("./../../../src/layouts/ProjectPage.tsx" /* webpackChunkName: "component---src-layouts-project-page-tsx" */),
  "component---src-layouts-translation-not-available-page-tsx": () => import("./../../../src/layouts/TranslationNotAvailablePage.tsx" /* webpackChunkName: "component---src-layouts-translation-not-available-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kariera-tsx": () => import("./../../../src/pages/kariera.tsx" /* webpackChunkName: "component---src-pages-kariera-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-o-nas-tsx": () => import("./../../../src/pages/o-nas.tsx" /* webpackChunkName: "component---src-pages-o-nas-tsx" */),
  "component---src-pages-rodo-tsx": () => import("./../../../src/pages/rodo.tsx" /* webpackChunkName: "component---src-pages-rodo-tsx" */),
  "component---src-pages-tools-harmony-tsx": () => import("./../../../src/pages/tools/harmony.tsx" /* webpackChunkName: "component---src-pages-tools-harmony-tsx" */),
  "component---src-pages-tools-lis-tsx": () => import("./../../../src/pages/tools/lis.tsx" /* webpackChunkName: "component---src-pages-tools-lis-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-uslugi-drony-tsx": () => import("./../../../src/pages/uslugi/drony.tsx" /* webpackChunkName: "component---src-pages-uslugi-drony-tsx" */),
  "component---src-pages-uslugi-energetyka-tsx": () => import("./../../../src/pages/uslugi/energetyka.tsx" /* webpackChunkName: "component---src-pages-uslugi-energetyka-tsx" */),
  "component---src-pages-uslugi-nieruchomosci-tsx": () => import("./../../../src/pages/uslugi/nieruchomosci.tsx" /* webpackChunkName: "component---src-pages-uslugi-nieruchomosci-tsx" */),
  "component---src-pages-uslugi-srodowisko-carbonfootprint-tsx": () => import("./../../../src/pages/uslugi/srodowisko/carbonfootprint.tsx" /* webpackChunkName: "component---src-pages-uslugi-srodowisko-carbonfootprint-tsx" */),
  "component---src-pages-uslugi-srodowisko-tsx": () => import("./../../../src/pages/uslugi/srodowisko.tsx" /* webpackChunkName: "component---src-pages-uslugi-srodowisko-tsx" */),
  "component---src-pages-uslugi-transport-tsx": () => import("./../../../src/pages/uslugi/transport.tsx" /* webpackChunkName: "component---src-pages-uslugi-transport-tsx" */),
  "component---src-pages-uslugi-tsx": () => import("./../../../src/pages/uslugi.tsx" /* webpackChunkName: "component---src-pages-uslugi-tsx" */)
}

